import React from "react";
import { useEffect } from "react";
// import PropTypes from "prop-types";
// import { Link, graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
// import { css, cx } from "@emotion/css";

// import Layout from "../components/Layout";
// import Features from "../components/Features";
// import BlogRoll from "../components/BlogRoll";
// import FullWidthImage from "../components/FullWidthImage";

// const sectionClasses = {
//   fullHeightClasses: css({
//     height: "100vh",
//   }),
//   fullWidthClasses: css({
//     width: "100%",
//   }),
// };

// const Section = ({ fullWidth, fullHeight, children }) => {
//   const background = css({
//     background: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
//     color: "white",
//     fontSize: 21,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   });
//   return (
//     <section
//       className={cx(
//         { [sectionClasses.fullWidthClasses]: fullWidth },
//         { [sectionClasses.fullHeightClasses]: fullHeight },
//         background
//       )}
//     >
//       {children}
//     </section>
//   );
// };

// const HomePage = () => {
//   return (
//     <>
//       <Section fullHeight>
//         “Greatness and beauty do not belong to the gods alone.” -Nigerian
//         Proverb
//       </Section>
//       <Section fullHeight>
//         Custom orders welcome. For enquiries on custom orders or particular{" "}
//         pieces, feel free to email us.
//       </Section>
//       <Section fullHeight>Our latest creations</Section>
//     </>
//   );
// };

// // eslint-disable-next-line
// export const IndexPageTemplate = ({
//   image,
//   title,
//   heading,
//   subheading,
//   mainpitch,
//   description,
//   intro,
// }) => {
//   const heroImage = getImage(image) || image;

//   return (
//     <div>
//       <FullWidthImage img={heroImage} title={title} subheading={subheading} />
//       <section className="section section--gradient">
//         <div className="container">
//           <div className="section">
//             <div className="columns">
//               <div className="column is-10 is-offset-1">
//                 <div className="content">
//                   <div className="content">
//                     <div className="tile">
//                       <h1 className="title">{mainpitch.title}</h1>
//                     </div>
//                     <div className="tile">
//                       <h3 className="subtitle">{mainpitch.description}</h3>
//                     </div>
//                   </div>
//                   <div className="columns">
//                     <div className="column is-12">
//                       <h3 className="has-text-weight-semibold is-size-2">
//                         {heading}
//                       </h3>
//                       <p>{description}</p>
//                     </div>
//                   </div>
//                   <Features gridItems={intro.blurbs} />
//                   <div className="columns">
//                     <div className="column is-12 has-text-centered">
//                       <Link className="btn" to="/products">
//                         See all products
//                       </Link>
//                     </div>
//                   </div>
//                   <div className="column is-12">
//                     <h3 className="has-text-weight-semibold is-size-2">
//                       Latest stories
//                     </h3>
//                     <BlogRoll />
//                     <div className="column is-12 has-text-centered">
//                       <Link className="btn" to="/blog">
//                         Read more
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// IndexPageTemplate.propTypes = {
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   title: PropTypes.string,
//   heading: PropTypes.string,
//   subheading: PropTypes.string,
//   mainpitch: PropTypes.object,
//   description: PropTypes.string,
//   intro: PropTypes.shape({
//     blurbs: PropTypes.array,
//   }),
// };

const IndexPage = ({ data }) => {
  // const { frontmatter } = data.markdownRemark;

  useEffect(() => {
    const isBrowser = () => typeof window !== "undefined";
    isBrowser() &&
      window.location.replace("https://www.etsy.com/ca/shop/UzuriDesignCA");
  }, []);

  return (
    // <Layout>
    //   <HomePage />
    // </Layout>\<>
    null
  );
};

export default IndexPage;

// export const pageQuery = graphql`
//   query IndexPageTemplate {
//     markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
//       frontmatter {
//         title
//         image {
//           childImageSharp {
//             gatsbyImageData(quality: 100, layout: FULL_WIDTH)
//           }
//         }
//         heading
//         subheading
//         mainpitch {
//           title
//           description
//         }
//         description
//         intro {
//           blurbs {
//             image {
//               childImageSharp {
//                 gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
//               }
//             }
//             text
//           }
//           heading
//           description
//         }
//       }
//     }
//   }
// `;
